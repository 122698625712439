import React from 'react';
import { Header } from 'components/molecules/Header';
import { ArtistsList } from 'components/molecules/ArtistsList';
import { Search } from 'components/atoms/Search';
import { Link, navigate } from 'gatsby';
import { SIGNUP_SCREEN } from 'router/routes';
import Seo from '../../seo';
import { FooterSm } from '../../molecules/FooterSm';
import * as homeStyles from '../Home/style.module.scss';
import * as styles from './style.module.scss';
import { Button } from '../../atoms/Button';
import { Back } from '../../atoms/Icons/Back';

type Props = {
	type?: string;
};
export const LandingHome: React.FC<Props> = ({ type = 'guitar' }) => {
	return (
		<>
			<Seo title="Landing" />
			<div className={homeStyles.container} data-testid="test">
				<div className={`${homeStyles.banner} ${styles[`banner_${type}`]}`}>
					<div className={homeStyles.headerContainer}>
						<Header />
					</div>
					<div className={homeStyles.banner_container} />
				</div>
				<div className={`${homeStyles.searchContainer} ${styles.searchContainer}`}>
					<Button className="back" onClick={() => navigate(-1)}>
						<>
							<Back /> Back
						</>
					</Button>
					<Search />
				</div>
				<section className={homeStyles.artistsSection}>
					<div className={homeStyles.homeContainer}>
						<ArtistsList isLanding />
					</div>
				</section>
				<section className={homeStyles.personalSection}>
					<div className={homeStyles.personalCreate}>
						<p className={homeStyles.createTitle}>
							Get intimate, personalized instruction and education from your idols.
						</p>
						<Link to={SIGNUP_SCREEN}>
							<button type="button" className={homeStyles.personalBtn}>
								Create an account
							</button>
						</Link>
					</div>
					<div className={homeStyles.personalizedContainer}>
						<div className={homeStyles.personalized} />
					</div>
				</section>
				<FooterSm />
			</div>
		</>
	);
};

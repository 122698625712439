// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--4pljO";
export var banner_guitar = "style-module--banner_guitar--87tnj";
export var chromeframe = "style-module--chromeframe--75011";
export var errorText = "style-module--errorText--zmr+h";
export var overlay = "style-module--overlay--yMxs4";
export var searchContainer = "style-module--searchContainer--VPmXx";
export var textDefault = "style-module--text-default--UCzyn";
export var textInput = "style-module--text-input--z72WF";
export var videoJs = "style-module--video-js--NXEIF";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--MkyCQ";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--iKjgI";
export var vjsPoster = "style-module--vjs-poster--keG0q";
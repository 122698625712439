import React from 'react';
import Layout from 'components/layout';
import { LandingHome as LandingHomeOrganism } from '../components/organisms/LandingHome';

const LandingHome = () => {
	return (
		<Layout>
			<LandingHomeOrganism />
		</Layout>
	);
};

export default LandingHome;

import React from 'react';

export const Back: React.FC = () => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M33.3333 18.3335H6.66667C5.74619 18.3335 5 19.0797 5 20.0002C5 20.9206 5.74619 21.6668 6.66667 21.6668H33.3333C34.2538 21.6668 35 20.9206 35 20.0002C35 19.0797 34.2538 18.3335 33.3333 18.3335Z"
				fill="#F1E1D5"
			/>
			<path
				d="M17.8452 11.1787C18.4961 10.5278 18.4961 9.47253 17.8452 8.82165C17.1943 8.17078 16.139 8.17078 15.4882 8.82165L5.48816 18.8217C4.83728 19.4725 4.83728 20.5278 5.48816 21.1787L15.4882 31.1787C16.139 31.8295 17.1943 31.8295 17.8452 31.1787C18.4961 30.5278 18.4961 29.4725 17.8452 28.8217L9.02369 20.0002L17.8452 11.1787Z"
				fill="#F1E1D5"
			/>
		</svg>
	);
};
